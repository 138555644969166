import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { useCartState } from '@components/cart/State/state'
import { useCallback, useMemo, useState } from 'react'
import type { OrderSaveBody } from '@pages/api/order/save'
import type { SaveOrderResponse } from '@api/order'
import { useRouter } from 'next/router'
import { useAppState } from '@context/state'
import type { BankPaymentResponse, CheckoutResult, EstoPaymentResponse } from '@api/checkout/models'
import { isTotalCoveredByGiftCoupons } from '@utils/orderTotals'
import { useTranslate } from '@hooks/useTranslation'
import { useCart } from '@hooks/useCart'

type SaveOrderAndCheckoutResponse = AxiosResponse<SaveOrderResponse & { paymentResults: CheckoutResult }>
const billingCountry: { [keys: string]: string } = {
  et: 'Estonia',
  en: 'Estonia',
  ru: 'Estonia',
  fi: 'Finland',
  sv: 'Sweden',
  lv: 'Latvia',
  lt: 'Latvia',
}

export const useSaveOrder = () => {
  const {
    dispatch,
    state: {
      order,
      paymentMethod,
      paymentTypeDetails,
      selectedDeliveryPoint,
      deliveryCountry,
      deliveryMethodsByCountry,
      storedPaymentMethod,
      homeDeliveryAddress,
      coupons,
      businessClientData,
      deliveryContactData,
      giftRecipientContactData,
    },
  } = useCartState()
  const { translate } = useTranslate()
  const { locale, push } = useRouter()
  const {
    state: { customer },
  } = useAppState()
  const { clearBasket } = useCart('shopping cart')
  const [saving, setSaving] = useState<boolean>(false)
  const [orderSaveError, setOrderSaveError] = useState<string | null>(null)

  const selectedDeliveryMethod = useMemo(
    () => deliveryMethodsByCountry[deliveryCountry],
    [deliveryMethodsByCountry, deliveryCountry]
  )

  const isHomeDelivery = useMemo(() => {
    const isTaxi = selectedDeliveryMethod?.konakartCarrierCode === 'EKSPRESS'
    return (
      (selectedDeliveryMethod?.express && isTaxi) ||
      (!selectedDeliveryMethod?.parcelMachine && !selectedDeliveryMethod?.express)
    )
  }, [selectedDeliveryMethod])

  const onProceedClick = useCallback(async () => {
    if (!order) return

    try {
      setSaving(true)
      setOrderSaveError(null)
      order.billingCountry = billingCountry[locale as string]
      if (!customer?.id) {
        order.customerName = `${deliveryContactData.firstName} ${deliveryContactData.lastName}`
      }
      order.customerEmail = deliveryContactData.email
      order.customerTelephone = deliveryContactData.telephone

      if (giftRecipientContactData.allFilled) {
        order.deliveryEmail = giftRecipientContactData.email
        order.deliveryTelephone = giftRecipientContactData.telephone
        order.deliveryName = `${giftRecipientContactData.firstName} ${giftRecipientContactData.lastName}`
        order.sent_as_gift = 'true'
        order.custom15 = 'true'
      } else {
        order.deliveryEmail = deliveryContactData.email
        order.deliveryTelephone = deliveryContactData.telephone
        order.deliveryName = `${deliveryContactData.firstName} ${deliveryContactData.lastName}`
        order.sent_as_gift = 'false'
      }

      const ok =
        (deliveryContactData.allFilled && selectedDeliveryMethod && homeDeliveryAddress) ||
        (selectedDeliveryMethod && selectedDeliveryPoint)
      if (!ok) {
        setSaving(false)
        dispatch({ type: 'setOrderSaveError', payload: translate('cart.fields.not.filled.error') })
        window.scrollTo({ top: 10, behavior: 'auto' })

        return
      }

      order.deliveryCountry = deliveryCountry
      order.custom10 = selectedDeliveryMethod?.konakartCarrierCode
      order.deliveryCompany = selectedDeliveryMethod?.konakartCarrierCode

      if (selectedDeliveryPoint && selectedDeliveryMethod) {
        const selectedDeliveryCity = selectedDeliveryPoint.city || selectedDeliveryPoint.name.split(' ')[0]
        order.deliveryStreetAddress = selectedDeliveryPoint.address1
        order.deliveryCity = selectedDeliveryCity
        order.deliveryPostcode = selectedDeliveryPoint.zipCode
        order.custom11 = selectedDeliveryPoint.routingCode || selectedDeliveryPoint.zipCode
        order.deliverySuburb = selectedDeliveryPoint.name
        order.deliveryStreetAddress1 = selectedDeliveryPoint.id
        order.customerStreetAddress = selectedDeliveryPoint.address1
        order.customerCity = selectedDeliveryCity
        order.customerPostcode = selectedDeliveryPoint.zipCode
        order.billingPostcode = selectedDeliveryPoint.zipCode
        order.billingStreetAddress = selectedDeliveryPoint.address1
        order.billingCity = selectedDeliveryCity
        if (
          homeDeliveryAddress &&
          ['BUDBEESPFI', 'POSTNORDSPSE', 'POSTNORDSVCSE'].includes(selectedDeliveryMethod.konakartCarrierCode)
        ) {
          order.customerStreetAddress = homeDeliveryAddress.streetAddress
          order.customerCity = homeDeliveryAddress.city
          order.customerCountry = deliveryCountry
        } else {
          order.customerStreetAddress = selectedDeliveryPoint.address1
        }
      } else if (isHomeDelivery && homeDeliveryAddress && homeDeliveryAddress.allFilled && selectedDeliveryMethod) {
        order.deliveryStreetAddress = homeDeliveryAddress.streetAddress
        order.deliveryCity = homeDeliveryAddress.city
        order.deliveryPostcode = homeDeliveryAddress.zip
        order.customerStreetAddress = order.customerStreetAddress || homeDeliveryAddress.streetAddress
        order.customerCity = order.customerCity || homeDeliveryAddress.city
        order.customerPostcode = order.customerPostcode || homeDeliveryAddress.zip
        order.billingStreetAddress = order.billingStreetAddress || homeDeliveryAddress.streetAddress
        order.billingCity = order.billingCity || homeDeliveryAddress.city
        order.billingPostcode = order.billingPostcode || homeDeliveryAddress.zip
        if (selectedDeliveryMethod.konakartCarrierCode === 'EKSPRESS') {
          order.deliveryCompany = 'Tallink'
        }
      }
      if (businessClientData?.allFilled) {
        order.billingCompany = businessClientData.companyName
        order.custom13 = businessClientData.regNumber
        order.custom14 = businessClientData.vatNumber ?? order.custom14
        order.billingStreetAddress = businessClientData.street
        order.billingCity = businessClientData.city
        order.billingPostcode = businessClientData.zip
        order.billingCountry = businessClientData.country
      }
      const { data } = await axios.post<OrderSaveBody, SaveOrderAndCheckoutResponse>('/api/order/save', {
        locale,
        order: {
          ...order,
          paymentMethod,
          ...(paymentMethod === 'banks' && paymentTypeDetails && { ccType: paymentTypeDetails, custom2: 'banks' }),
          ...(paymentMethod === 'esto' &&
            paymentTypeDetails && { paymentModuleCode: paymentTypeDetails, custom2: 'esto' }),
          ...(isTotalCoveredByGiftCoupons(order) && {
            custom2: 'giftCoupons',
            paymentMethod: 'giftCoupons',
            giftCoupons: JSON.stringify(
              coupons.map((c) => ({ currency: order.currencyCode, code: c.coupon })),
              null,
              0
            ),
          }),
        },
        buyAsGuest: customer?.id,
        origin: window.origin,
        ...(customer?.id && {
          clientInfo: {
            ssoToken: customer.ssoToken,
            email: customer.emailAddr,
            phone: customer.telephoneNumber,
          },
          ...(storedPaymentMethod &&
            storedPaymentMethod.id.length > 3 && { storedPaymentMethodId: storedPaymentMethod?.id }),
        }),
      })
      if (data.orderHash && typeof window !== undefined) {
        window.sessionStorage.setItem('currentOrderHash', data.orderHash)
      }
      if (paymentMethod === 'klarna' && data.orderHash) {
        setSaving(false)
        await push(`/klarna/${data.orderHash}`)
      }
      if (data.orderHash && order.giftCertCode && data.paymentResults === null) {
        setSaving(false)
        await push(`/confirmation/${data.orderHash}`)
        return
      }
      if (data.paymentResults.result === 'VERIFY' && typeof data.paymentResults.paymentResponse !== 'string') {
        if (isTotalCoveredByGiftCoupons(order) && data.orderHash) {
          setSaving(false)
          await push(`/confirmation/${data.orderHash}`)
          clearBasket()
          return
        }

        if (paymentMethod === 'dibs' && 'redirectLink' in data.paymentResults.paymentResponse)
          window.location.href = data.paymentResults.paymentResponse.redirectLink

        if (paymentMethod === 'banks' && data.paymentResults.paymentResponse) {
          dispatch({
            type: 'setBankPaymentResponse',
            payload: data.paymentResults.paymentResponse as BankPaymentResponse,
          })
        }

        if (paymentMethod === 'esto' && data.paymentResults.paymentResponse) {
          const { url, redirectUrl } = data.paymentResults.paymentResponse as EstoPaymentResponse
          setSaving(false)
          window.location.href = redirectUrl || url
        }
      } else {
        console.error(data.paymentResults)
      }

      setSaving(false)
    } catch (e) {
      setSaving(false)
      if (axios.isAxiosError(e) && e.response) {
        setOrderSaveError(e.response.data?.message)
      }
    }
  }, [
    order,
    dispatch,
    locale,
    push,
    paymentMethod,
    paymentTypeDetails,
    setSaving,
    setOrderSaveError,
    selectedDeliveryPoint,
    deliveryCountry,
    selectedDeliveryMethod,
    storedPaymentMethod,
    homeDeliveryAddress,
    coupons,
    customer,
    deliveryContactData,
    translate,
    isHomeDelivery,
  ])

  return {
    onProceedClick,
    saving,
    orderSaveError,
  }
}
